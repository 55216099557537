<template>
  <div class="special-offer__left pt-sm pb-10">
    <h3 class="uppercase text-heading-page tracking-average text-center leading-lg text-black font-normal" v-if="item.head">{{ item.head }}</h3>
    <div class="text-center mt-13">
      <p class="uppercase leading-none text-h3" >{{ item.title }}</p>
      <p v-if="item.subtitle" class="mt-md text-heading-page leading-none">{{ item.subtitle }}</p>
      <component v-bind="linkProps(item.link)" class="mt-22 inline-block tracking-2xl text-sm leading-h4 special-offer__link uppercase relative">
        {{ $t('Shop Now') }}
      </component>
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'SpecialOfferItemLeft',
  extends: Blok,
  methods: {
    linkProps (item) {
      let linktype = item.linktype

      if (linktype === 'url') {
        return {
          is: 'a',
          href: item.url,
          rel: 'noopener'
        }
      }

      return {
        is: 'router-link',
        to: '/' + item.cached_url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @special-offer: ~'.special-offer';
  .special-offer {
    $self: &;

    &__link {
      &:after {
        transition: all 0.2s ease-in-out;
        @apply inline-block bg-black left-0 absolute w-full;
        content: '';
        bottom: 1px;
        height: 1px;
      }
      &:hover {
        &:after {
          opacity: 0;
        }
      }
    }

    &--even {
      #{ $self }__left {
        @apply bg-white;
      }
    }

    &--odd {
      #{ $self }__left {
        @apply bg-grey-27;
      }
    }
  }
</style>
